/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const EntryDtoReviewStatusEnum = {
  ToBeChecked: 'Prüfung ausstehend',
  Declined: 'Abgelehnt',
  Accepted: 'Akzeptiert'
} as const;

export const SortModes = {
  Newest: 'newest',
  Oldest: 'oldest',
  LastEdited: 'lastEdited',
  Asc: 'asc',
  Desc: 'desc'
} as const;

export type SortMode = (typeof SortModes)[keyof typeof SortModes];

export const SortModeMap: Map<string, SortMode> = new Map(
  Object.values(SortModes).map((value) => [`${value}`, value] as const)
);

export const getSortMode = (sortMode?: string | null): SortMode =>
  SortModeMap.get(sortMode ?? 'newest') ?? SortModes.Newest;
