/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import {
  getIcon,
  hasValue,
  isEmptyOrNull,
  SLRAccordionContent,
  SLRLogoEditorIcons
} from '@SLR/shared-library';
import { t } from 'i18next';
import { FieldErrors } from 'react-hook-form';
import { EntryInput } from '..';
import BaseInformation from './base-information/BaseInformation';
import Description from './description/Description';
import Faqs from './faqs/Faqs';
import Media from './media/Media';
import PriceAndContact from './price-contact/PriceAndContact';
import Testimonials from './testimonials/Testimonials';

// Note view key values should be the same as used in localization
export const FormKeys = {
  BaseInformation: 'baseInformation',
  Description: 'description',
  Media: 'media',
  Testimonials: 'testimonials',
  Faqs: 'faqs',
  PriceAndContact: 'priceAndContact'
} as const;

export const getCreateFormViews = (
  formErrors: FieldErrors<EntryInput>
): SLRAccordionContent[] => [
  {
    key: FormKeys.BaseInformation,
    icon: getIcon('fal', 'circle-1'),
    title: t('marketplace.entry.create.baseInformation.title'),
    form: <BaseInformation />,
    hasError:
      !!formErrors.name ||
      !!formErrors.subHeadline ||
      !!formErrors.logoId ||
      !!formErrors.categories ||
      !!formErrors.targetGroups ||
      !!formErrors.endDevices
  },
  {
    key: FormKeys.Description,
    icon: getIcon('fal', 'circle-2'),
    title: t('marketplace.entry.create.description.title'),
    form: <Description />,
    hasError:
      !!formErrors.shortDescription ||
      !!formErrors.description ||
      !!formErrors.declarations
  },
  {
    key: FormKeys.Media,
    icon: getIcon('fal', 'circle-3'),
    title: t('marketplace.entry.create.media.title'),
    form: <Media />,
    hasError: !!formErrors.previewImageId || !!formErrors.media
  },
  {
    key: FormKeys.Testimonials,
    icon: getIcon('fal', 'circle-4'),
    title: t('marketplace.entry.create.testimonials.title'),
    form: <Testimonials />,
    hasError: !!formErrors.testimonials
  },
  {
    key: FormKeys.Faqs,
    icon: getIcon('fal', 'circle-5'),
    title: t('marketplace.entry.create.faqs.title'),
    form: <Faqs />,
    hasError: !!formErrors.faqs
  },
  {
    key: FormKeys.PriceAndContact,
    icon: getIcon('fal', 'circle-6'),
    title: t('marketplace.entry.create.priceAndContact.title'),
    form: <PriceAndContact />,
    hasError:
      !!formErrors.priceInformation ||
      !!formErrors.longPriceInformation ||
      !!formErrors.contact
  }
];

export const getEntryMediaItems = (entry?: Nullable<EntryDto>) => {
  const entryMedia: string[] = [];
  if (entry) {
    if (!isEmptyOrNull(entry.logoId)) entryMedia.push(entry.logoId);

    if (!isEmptyOrNull(entry.previewImageId))
      entryMedia.push(entry.previewImageId);

    if (hasValue(entry.media)) {
      entry.media.forEach((media) => entryMedia.push(media));
    }

    if (hasValue(entry.testimonials)) {
      entry.testimonials.forEach((testimonial) => {
        if (!isEmptyOrNull(testimonial.authorPictureId))
          entryMedia.push(testimonial.authorPictureId);
      });
    }

    if (entry.contact && !isEmptyOrNull(entry.contact?.pictureId))
      entryMedia.push(entry.contact?.pictureId);
  }

  return entryMedia.sort((a, b) => a.localeCompare(b));
};

// Shared accordion props
export const accordionProps = {
  icons: {
    errorIcon: getIcon('fal', 'circle-exclamation'),
    deleteIcon: getIcon('fal', 'trash-can'),
    dragIcon: getIcon('fal', 'grip-dots-vertical'),
    arrowIcon: getIcon('fal', 'chevron-down')
  },
  errorMessage: 'marketplace.entry.create.error.partialError'
};

// Form icons
export const logoEditorIcons: SLRLogoEditorIcons = {
  uploadIcon: getIcon('fal', 'cloud-upload'),
  deleteIcon: getIcon('fal', 'trash-alt'),
  rotateIcon: getIcon('fal', 'redo'),
  fallbackIcon: getIcon('fal', 'image-slash')
};
