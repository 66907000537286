/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRPageHead } from '@SLR/shared-library';
import { useMarketplace } from 'providers';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsItem } from '.';

const Hero: FC = () => {
  const { t } = useTranslation();
  const { isManagementContext, isReviewContext } = useMarketplace();

  return isManagementContext || isReviewContext ? (
    <SLRPageHead
      className="pt-8"
      labels={t(
        `marketplace.pageHead.${isManagementContext ? 'management' : 'review'}`,
        { returnObjects: true }
      )}
    />
  ) : (
    <NewsItem />
  );
};

export default memo(Hero);
