/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDto,
  EntryDtoCategoriesEnum,
  EntryDtoStatusEnum
} from '@SLR/marketplaceService-sdk';
import { EntryDtoReviewStatusEnum, SortModes } from 'configs';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { useMarketplace } from 'providers';

const useEntries = () => {
  const {
    selectedFilter,
    selectedSortMode,
    isReviewContext,
    isManagementContext
  } = useMarketplace();

  const onSelect = (entries: EntryDto[]): EntryDto[] => {
    const filtered = entries?.filter((entry) => {
      if (selectedFilter === t('marketplace.filter.all'))
        // In case user is in review context do not show drafts
        return isReviewContext && entry?.status === EntryDtoStatusEnum.Entwurf
          ? false
          : true;
      else {
        if (isReviewContext) {
          return (
            (entry?.status === EntryDtoStatusEnum.InPrfung &&
              selectedFilter === EntryDtoReviewStatusEnum.ToBeChecked) ||
            (entry?.status === EntryDtoStatusEnum.NderungenNotwendig &&
              selectedFilter === EntryDtoReviewStatusEnum.Declined) ||
            ((entry?.status === EntryDtoStatusEnum.BereitZumVerffentlichen ||
              entry?.status === EntryDtoStatusEnum.Verffentlicht) &&
              selectedFilter === EntryDtoReviewStatusEnum.Accepted)
          );
        } else if (isManagementContext) {
          return entry?.status === (selectedFilter as EntryDtoStatusEnum);
        } else {
          return entry?.categories?.includes(
            selectedFilter as EntryDtoCategoriesEnum
          );
        }
      }
    });

    if (
      selectedSortMode === SortModes.Newest ||
      selectedSortMode === SortModes.Oldest
    ) {
      return orderBy(
        filtered,
        [
          isReviewContext
            ? 'updatedAt'
            : isManagementContext
              ? 'created'
              : 'publishedAt'
        ],
        [selectedSortMode === SortModes.Oldest ? 'asc' : 'desc']
      );
    } else if (selectedSortMode === SortModes.LastEdited) {
      return orderBy(filtered, ['updatedAt'], ['desc']);
    } else {
      return orderBy(
        filtered,
        [(entry) => entry?.name?.toLowerCase()],
        [selectedSortMode === SortModes.Asc ? 'asc' : 'desc']
      );
    }
  };

  return { onSelect };
};

export default useEntries;
