/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ContactPersonDto } from '@SLR/marketplaceService-sdk';
import {
  EMAIL_PATTERN_VALIDATION,
  URL_PATTERN_VALIDATION,
  isEmptyOrNull
} from '@SLR/shared-library';
import { ObjectSchema, object, string } from 'yup';

export const ContactSchema: ObjectSchema<Nullable<ContactPersonDto>> =
  object().shape({
    id: string(),
    name: string()
      .required('marketplace.entry.create.formErrors.contactPerson.name')
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.contactPerson.name',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    email: string()
      .required('marketplace.entry.create.formErrors.contactPerson.email')
      .matches(
        EMAIL_PATTERN_VALIDATION,
        'marketplace.entry.create.formErrors.contactPerson.invalidEmail'
      )
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.contactPerson.email',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    pictureId: string().default(null).nullable(),
    phone: string().default(null),
    website: string()
      .nullable()
      .test(
        'matches',
        'marketplace.entry.create.formErrors.contactPerson.invalidWebsite',
        (value) =>
          value?.length === 0 || URL_PATTERN_VALIDATION.test(value as string)
      )
      .default(null)
  });
