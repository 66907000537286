/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDtoStatusEnum } from '@SLR/marketplaceService-sdk';
import { EntryDtoReviewStatusEnum } from 'configs';

const getReviewStatusName = (status?: string | null) => {
  if (status === EntryDtoStatusEnum.InPrfung)
    return EntryDtoReviewStatusEnum.ToBeChecked;
  else if (status === EntryDtoStatusEnum.NderungenNotwendig)
    return EntryDtoReviewStatusEnum.Declined;
  else if (
    status === EntryDtoStatusEnum.BereitZumVerffentlichen ||
    status === EntryDtoStatusEnum.Verffentlicht
  )
    return EntryDtoReviewStatusEnum.Accepted;
  else return status;
};

const getManagementStatusColor = (status?: string | null) => {
  if (status === EntryDtoStatusEnum.Entwurf) return 'info-neutral';
  else if (status === EntryDtoStatusEnum.NderungenNotwendig) return 'danger';
  else if (status === EntryDtoStatusEnum.Verffentlicht) return 'success';
  else if (
    status === EntryDtoStatusEnum.InPrfung ||
    status === EntryDtoStatusEnum.BereitZumVerffentlichen
  )
    return 'secondary';
  else return 'primary';
};

const getReviewStatusColor = (status?: string | null) => {
  if (status === EntryDtoStatusEnum.NderungenNotwendig) return 'danger';
  else if (
    status === EntryDtoStatusEnum.BereitZumVerffentlichen ||
    status === EntryDtoStatusEnum.Verffentlicht
  )
    return 'success';
  else if (status === EntryDtoStatusEnum.InPrfung) return 'warning-dark';
  else return 'primary';
};

export const getBadgeInfo = (
  status?: string | null,
  isReviewContext = false
): {
  badgeName: string | undefined | null;
  badgeColor: string;
} => {
  const badgeName = isReviewContext ? getReviewStatusName(status) : status;
  const badgeColor = isReviewContext
    ? getReviewStatusColor(status)
    : getManagementStatusColor(status);

  return { badgeName, badgeColor };
};
