/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { TestimonialDto } from '@SLR/marketplaceService-sdk';
import { SLRMediaItem } from '@SLR/shared-library';
import { defaultPersonLogo } from 'assets';
import { CarouselArrow } from 'components';
import { FC, memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Testimonials.scss';

type TestimonialsProps = {
  testimonials: TestimonialDto[];
};

const Testimonials: FC<TestimonialsProps> = ({ testimonials }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.testimonials'
  });

  return (
    <Container fluid className="testimonials my-3 px-6 py-2">
      <Container>
        <Slider
          slidesToShow={2}
          infinite={testimonials.length > 1}
          arrows={testimonials.length > 1}
          prevArrow={
            <CarouselArrow
              direction="left"
              componentClassName="text-white"
              iconTitle={t('left')}
              iconSize="3x"
            />
          }
          nextArrow={
            <CarouselArrow
              direction="right"
              componentClassName="text-white"
              iconTitle={t('right')}
              iconSize="3x"
            />
          }
          responsive={[
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1
              }
            }
          ]}
        >
          {testimonials?.map((testimonial, index) => (
            <div
              className="testimonial"
              key={`testimonial-${testimonial.id}-${index}`}
            >
              <Row>
                <Col className="testimonial-text">{`“${testimonial.text}”`}</Col>
              </Row>
              <Row className="mt-4">
                <Col xs="auto">
                  <SLRMediaItem
                    mediaItemId={testimonial?.authorPictureId}
                    altFallback={t('pictureAlt', {
                      author: testimonial?.authorName
                    })}
                    errorFallback={defaultPersonLogo}
                    roundedCircle
                    className="testimonial-image"
                  />
                </Col>
                <Col className="testimonial-author my-auto">
                  <Row>
                    <Col className="fw-bold">{testimonial.authorName}</Col>
                  </Row>
                  <Row>
                    <Col>{testimonial.authorRole}</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </Slider>
      </Container>
    </Container>
  );
};

export default memo(Testimonials);
