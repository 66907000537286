/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import { emptyAsFallback, hasValue } from '@SLR/shared-library';
import { FC, memo, useMemo, useState } from 'react';
import {
  Button,
  CloseButton,
  Col,
  Container,
  Modal,
  Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EntryDetail, EntryItem } from '../..';

import './EntryPreview.scss';

export type EntryPreviewProps = {
  entry: Nullable<EntryDto>;
  onClose?: () => void;
};

const EntryPreview: FC<EntryPreviewProps> = ({ entry, onClose }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.preview'
  });

  const [isPagePreview, setIsPagePreview] = useState<boolean>(true);

  const entryPreview: Nullable<EntryDto> = useMemo(
    () => ({
      ...entry,
      name: emptyAsFallback(t('entry.name'), entry.name),
      shortDescription: emptyAsFallback(
        t('entry.shortDescription'),
        entry.shortDescription
      ),
      subHeadline: emptyAsFallback(t('entry.subHeadline'), entry.subHeadline),
      priceInformation: emptyAsFallback(
        t('entry.priceInformation'),
        entry.priceInformation
      ),
      description: emptyAsFallback(t('entry.description'), entry.description),
      contact: {
        ...entry.contact,
        name: emptyAsFallback(t('entry.contact.name'), entry.contact?.name)
      },
      testimonials: hasValue(entry.testimonials)
        ? entry.testimonials.map((testimonial) => ({
            ...testimonial,
            text: emptyAsFallback(
              t('entry.testimonials.text'),
              testimonial.text
            ),
            authorName: emptyAsFallback(
              t('entry.testimonials.authorName'),
              testimonial.authorName
            ),
            authorRole: emptyAsFallback(
              t('entry.testimonials.authorRole'),
              testimonial.authorRole
            )
          }))
        : [],
      faqs: hasValue(entry.faqs)
        ? entry.faqs.map((faq) => ({
            ...faq,
            question: emptyAsFallback(t('entry.faqs.question'), faq.question),
            answer: emptyAsFallback(t('entry.faqs.answer'), faq.answer)
          }))
        : []
    }),
    [entry, t]
  );

  return (
    <Modal show fullscreen onHide={onClose} className="p-0 entry-preview scale">
      <Modal.Header className="p-0">
        <Row className="w-100 m-0 py-3 align-items-center justify-content-between">
          <Col xs="2" sm="1"></Col>
          <Col xs="8" sm="10">
            <Row className="justify-content-center gap-3">
              <Col xs="auto" className="p-0">
                <Button
                  size="lg"
                  variant={isPagePreview ? 'primary' : 'outline-primary'}
                  onClick={() => setIsPagePreview(true)}
                >
                  {t('page')}
                </Button>
              </Col>
              <Col xs="auto" className="p-0">
                <Button
                  size="lg"
                  variant={isPagePreview ? 'outline-primary' : 'primary'}
                  onClick={() => setIsPagePreview(false)}
                >
                  {t('item')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs="2"
            sm="1"
            className="d-flex align-items-center justify-content-center"
          >
            <CloseButton onClick={onClose} className="m-0" />
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Container fluid className="p-0">
          {isPagePreview ? (
            <EntryDetail isPreview previewEntry={entryPreview} />
          ) : (
            <Container className="px-0">
              <Row
                xs={1}
                md={2}
                xl={3}
                className="m-0 pt-5 justify-content-center"
              >
                <EntryItem entry={entryPreview} isPreview />
              </Row>
            </Container>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default memo(EntryPreview);
