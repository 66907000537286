/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLegalRoute } from '@SLR/shared-library';
import { LegalTextType } from '@SLR/user-service-external-sdk';
import { matchPath } from 'react-router-dom';

// Legal
export const LEGAL_PATH = '/legal';
export const PRIVACY_POLICY_PATH = `${LEGAL_PATH}/privacy-policy`;
export const TERMS_OF_USE_PATH = `${LEGAL_PATH}/terms-of-use`;
export const IMPRINT_PATH = `${LEGAL_PATH}/imprint`;

export const legalRouts: SLRLegalRoute[] = [
  {
    path: PRIVACY_POLICY_PATH,
    legalTextType: LegalTextType.DataPrivacy
  },
  {
    path: TERMS_OF_USE_PATH,
    legalTextType: LegalTextType.EvbTerms
  },
  {
    path: IMPRINT_PATH,
    legalTextType: LegalTextType.Imprint
  }
];

// Marketplace
export const MARKETPLACE_PATH = '/';
export const MARKETPLACE_ENTRY_DETAILS_PATH = `${MARKETPLACE_PATH}:entryId`;

// Management
export const MARKETPLACE_MANAGEMENT_PATH = `${MARKETPLACE_PATH}management`;
export const MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH = `${MARKETPLACE_MANAGEMENT_PATH}/create`;
export const MARKETPLACE_MANAGEMENT_EDIT_ENTRY_PATH = `${MARKETPLACE_MANAGEMENT_PATH}/:entryId/edit`;

// Review
export const MARKETPLACE_REVIEW_PATH = `${MARKETPLACE_PATH}review`;
export const MARKETPLACE_REVIEW_ENTRY_DETAILS_PATH = `${MARKETPLACE_REVIEW_PATH}/:entryId`;
export const MARKETPLACE_REVIEW_EDIT_ENTRY_PATH = `${MARKETPLACE_REVIEW_ENTRY_DETAILS_PATH}/edit`;

export const isCreateOrEditPath = (currentPath?: string): boolean => {
  if (!currentPath) return false;
  return (
    Boolean(matchPath(MARKETPLACE_MANAGEMENT_CREATE_ENTRY_PATH, currentPath)) ||
    Boolean(matchPath(MARKETPLACE_MANAGEMENT_EDIT_ENTRY_PATH, currentPath)) ||
    Boolean(matchPath(MARKETPLACE_REVIEW_EDIT_ENTRY_PATH, currentPath))
  );
};
