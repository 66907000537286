/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ExtendedMediaItem, SLRModal } from '@SLR/shared-library';
import { FC, memo, useEffect, useState } from 'react';
import { Settings } from 'react-slick';
import GalleryCarousel from './GalleryCarousel';

import './Gallery.scss';

const modalSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        centerPadding: '30px'
      }
    }
  ]
};

export type GalleryProps = {
  entryName?: string | null;
  mediaItems: ExtendedMediaItem[];
};

const Gallery: FC<GalleryProps> = ({ entryName, mediaItems }) => {
  const [selectedImage, setSelectedImage] = useState<number | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => setShowModal(selectedImage !== undefined), [selectedImage]);
  return (
    <>
      <GalleryCarousel
        entryName={entryName}
        mediaItems={mediaItems}
        onImageClick={setSelectedImage}
      />
      {showModal && (
        <SLRModal
          size="xl"
          fullscreen="md-down"
          isOpen
          className="gallery-modal"
          backdropClassName="gallery-modal-backdrop"
          onClose={() => setSelectedImage(undefined)}
        >
          <GalleryCarousel
            entryName={entryName}
            selectedItem={selectedImage}
            mediaItems={mediaItems}
            settings={modalSettings}
            itemClassName="modal"
          />
        </SLRModal>
      )}
    </>
  );
};

export default memo(Gallery);
