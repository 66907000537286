/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FaqDto } from '@SLR/marketplaceService-sdk';
import { isEmptyOrNull } from '@SLR/shared-library';
import { ObjectSchema, object, string } from 'yup';

export const FAQ_MAX_COUNT = 10;
export const FAQ_QUESTION_MAX_CHAR_LENGTH = 150;
export const FAQ_ANSWER_MAX_CHAR_LENGTH = 1000;

export const emptyFaq: FaqDto = {
  id: '',
  answer: '',
  question: ''
};

export const FaqSchema: ObjectSchema<Nullable<FaqDto>> = object().shape({
  id: string().default(null),
  question: string()
    .required('marketplace.entry.create.formErrors.faq.question')
    .max(FAQ_QUESTION_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.faq.question',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  answer: string()
    .required('marketplace.entry.create.formErrors.faq.answer')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.faq.answer',
      (value) => !isEmptyOrNull(value)
    )
    .default(null)
});
