/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Declaration } from 'configs';
import { FC, memo, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DeclarationItem from './DeclarationItem';

type DeclarationsProps = {
  declarations: { [key: string]: boolean };
  onClick: () => void;
};

const Declarations: FC<DeclarationsProps> = ({ declarations, onClick }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.declarations'
  });

  const hasTrueValue = useMemo(
    () => Object.values(declarations).some((value) => value),
    [declarations]
  );

  return (
    <Container className="mb-6 declarations">
      <h2 className="mb-2 d-flex align-items-center">
        <span>{t('title')}</span>
        <span>
          <Button variant="link" onClick={onClick} title={t('infoButton')}>
            <FontAwesomeIcon icon={getIcon('fal', 'circle-info')} size="2x" />
          </Button>
        </span>
      </h2>
      <div className="mb-4">
        {hasTrueValue ? (
          t('specified')
        ) : (
          <span className="text-info-neutral fst-italic">
            {t('notSpecified')}
          </span>
        )}
      </div>
      <Container>
        <Row>
          {Object.entries(declarations)?.map(
            ([key, value]) =>
              value && (
                <Col xs="12" lg="6" key={key} className="mb-4">
                  <DeclarationItem declaration={key as Declaration} />
                </Col>
              )
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default memo(Declarations);
