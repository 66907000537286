/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRFormInfo,
  SLRLogoUpload,
  SLRMediaSizes,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FC, memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EntryInput,
  TESTIMONIALS_QUOTE_MAX_CHAR_LENGTH,
  logoEditorIcons
} from '../../../';

type TestimonialFormProps = {
  id: string;
  index: number;
  onTitleChange: (title?: string) => void;
};

const TestimonialForm: FC<TestimonialFormProps> = ({
  id,
  index,
  onTitleChange
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.testimonials.form'
  });

  const {
    formState: { errors },
    getValues,
    control,
    watch,
    register
  } = useFormContext<EntryInput>();

  const textCount = watch(`testimonials.${index}.text`)?.length ?? 0;

  return (
    <Row className="flex-column flex-lg-row-reverse testimonials-forms-item">
      <Col
        xs="auto"
        lg="4"
        xl="3"
        className={`text-center ${
          errors?.testimonials?.[index]?.authorPictureId ? 'text-danger' : ''
        }`}
      >
        <Form.Label htmlFor={`testimonials.${index}.authorPicture`}>
          {t('authorPicture')}
        </Form.Label>
        <SLRLogoUpload
          key={id}
          id={`testimonials.${index}.authorPicture`}
          name={`testimonials.${index}.authorPictureId`}
          control={control}
          mediaSize={SLRMediaSizes.Small}
          mediaOwner={{
            organizationId: getValues('organizationId') ?? undefined,
            owner: Ownership.Organization
          }}
          icons={logoEditorIcons}
        />
        <SLRFormInfo
          isInvalid={!!errors?.testimonials?.[index]?.authorPictureId}
          text={errors?.testimonials?.[index]?.authorPictureId?.message}
        />
      </Col>
      <Col xs="12" lg="8" xl="9">
        <Row className="gap-3 mb-3">
          <Col
            xs="12"
            className={
              errors?.testimonials?.[index]?.authorName ? 'text-danger' : ''
            }
          >
            <Form.Label htmlFor={`testimonials.${index}.authorName`}>
              {t('authorName')}
            </Form.Label>
            <Form.Control
              id={`testimonials.${index}.authorName`}
              type="text"
              className="form-control-lg"
              isInvalid={!!errors?.testimonials?.[index]?.authorName}
              {...register(`testimonials.${index}.authorName`, {
                onBlur: (e) =>
                  onTitleChange(
                    isEmptyOrNull(e.target.value) ? undefined : e.target.value
                  )
              })}
            />
            <SLRFormInfo
              isInvalid={!!errors?.testimonials?.[index]?.authorName}
              text={errors?.testimonials?.[index]?.authorName?.message}
            />
          </Col>
          <Col
            xs="12"
            className={
              errors?.testimonials?.[index]?.authorRole ? 'text-danger' : ''
            }
          >
            <Form.Label htmlFor={`testimonials.${index}.authorRole`}>
              {t('authorRole.title')}
            </Form.Label>
            <Form.Control
              id={`testimonials.${index}.authorRole`}
              type="text"
              placeholder={t('authorRole.placeholder')}
              className="form-control-lg"
              isInvalid={!!errors?.testimonials?.[index]?.authorRole}
              {...register(`testimonials.${index}.authorRole`)}
            />
            <SLRFormInfo
              isInvalid={!!errors?.testimonials?.[index]?.authorRole}
              text={errors?.testimonials?.[index]?.authorRole?.message}
            />
          </Col>
        </Row>
      </Col>
      <Col
        xs="12"
        className={errors?.testimonials?.[index]?.text ? 'text-danger' : ''}
      >
        <Form.Label htmlFor={`testimonials.${index}.text`}>
          {t('text.title')}
        </Form.Label>
        <Form.Control
          id={`testimonials.${index}.text`}
          type="text"
          as="textarea"
          rows={4}
          maxLength={TESTIMONIALS_QUOTE_MAX_CHAR_LENGTH}
          placeholder={t('text.placeholder')}
          className="form-control-lg"
          isInvalid={!!errors?.testimonials?.[index]?.text}
          {...register(`testimonials.${index}.text`)}
        />
        <SLRFormInfo
          isInvalid={!!errors?.testimonials?.[index]?.text}
          text={errors?.testimonials?.[index]?.text?.message}
          counter={{
            count: textCount,
            maxCount: TESTIMONIALS_QUOTE_MAX_CHAR_LENGTH
          }}
        />
      </Col>
    </Row>
  );
};

export default memo(TestimonialForm);
