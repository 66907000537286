/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRScrollToTop,
  SLRToastContainer,
  useClearToasts
} from '@SLR/shared-library';
import { ErrorView } from 'components';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { Footer, NavBar } from '.';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  useClearToasts();
  const { pathname } = useLocation();

  return (
    <div className="d-flex flex-column bg-white" style={{ minHeight: '100vh' }}>
      <SLRToastContainer position="top-end" className="mt-7" />
      <SLRScrollToTop />

      <header className="flex-shrink-0">
        <NavBar />
      </header>
      <main className="flex-grow-1">
        <ErrorBoundary key={pathname} fallback={<ErrorView />}>
          {children}
        </ErrorBoundary>
      </main>
      <footer className="flex-shrink-0">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
