/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRAccordion,
  SLRFormInfo,
  SLRLabelIcon,
  emptyAsFallback,
  getIcon,
  hasValue,
  isEmptyOrNull,
  moveFromTo
} from '@SLR/shared-library';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EntryInput,
  TESTIMONIALS_MAX_COUNT,
  accordionProps,
  emptyTestimonial
} from '../..';
import TestimonialForm from './form/TestimonialForm';

const Testimonials: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.testimonials'
  });

  const [activeTestimonialKey, setActiveTestimonialKey] = useState<string>();

  const {
    formState: { errors },
    control
  } = useFormContext<EntryInput>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'testimonials'
  });

  const isEmpty = useMemo(() => !hasValue(fields), [fields]);

  const [titles, setTitles] = useState<KeyValue>({
    0: undefined,
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined
  });

  const testimonialForms = useMemo(
    () =>
      fields.map((field, index) => ({
        key: `testimonial-${index}`,
        title: emptyAsFallback(
          t('form.title', {
            index: index + 1
          }),
          titles?.[index] ?? field.authorName
        ),
        form: (
          <TestimonialForm
            id={field.id}
            index={index}
            onTitleChange={(title) =>
              setTitles((prevTitles) => ({ ...prevTitles, [index]: title }))
            }
          />
        ),
        hasError: !!errors.testimonials?.[index]
      })),
    [fields, titles, t, errors.testimonials]
  );

  useEffect(() => {
    if (isEmptyOrNull(activeTestimonialKey))
      setActiveTestimonialKey('testimonial-0');
  }, [activeTestimonialKey, setActiveTestimonialKey]);

  const handleAddTestimonial = useCallback(() => {
    if (fields.length < TESTIMONIALS_MAX_COUNT) {
      append(emptyTestimonial);
      setActiveTestimonialKey(`testimonial-${fields.length}`);
    }
  }, [append, fields.length, setActiveTestimonialKey]);

  const handleDeleteTestimonial = useCallback(
    (index: number) => {
      remove(index);
      setTitles((prevTitles) => {
        const titlesArray = Object.values(prevTitles);
        titlesArray.splice(index, 1);
        return {
          ...titlesArray
        } as unknown as KeyValue;
      });
    },
    [remove]
  );

  const handleMoveTestimonial = useCallback(
    (oldIndex: number, newIndex: number) => {
      move(oldIndex, newIndex);
      setTitles(
        (prevTitles) =>
          ({
            ...moveFromTo(Object.values(prevTitles), oldIndex, newIndex)
          }) as unknown as KeyValue
      );
      setActiveTestimonialKey(`testimonial-${newIndex}`);
    },
    [move, setActiveTestimonialKey]
  );

  return (
    <Row>
      <Col xs="12">
        <Form.Label htmlFor="addTestimonial">
          {t('titleCount', {
            count: fields.length,
            maxCount: TESTIMONIALS_MAX_COUNT
          })}
        </Form.Label>

        <SLRFormInfo text={t('info')} />

        <SLRFormInfo text={t('additionalInfo')} />
        <SLRAccordion
          {...accordionProps}
          className="mt-5 mb-3"
          isDraggable={fields.length > 1}
          isDeletable
          onDelete={handleDeleteTestimonial}
          onMove={handleMoveTestimonial}
          defaultActiveKey={activeTestimonialKey}
          onKeyUpdate={setActiveTestimonialKey}
          content={testimonialForms}
        />
      </Col>
      <Col xs="12" className={`d-flex ${isEmpty ? '' : 'flex-row-reverse'}`}>
        <Button
          id="addTestimonial"
          variant="link"
          size="lg"
          disabled={fields.length >= TESTIMONIALS_MAX_COUNT}
          onClick={handleAddTestimonial}
          className="px-0"
        >
          <SLRLabelIcon
            icon={getIcon('fal', 'plus')}
            size="lg"
            label={t(isEmpty ? 'add.new' : 'add.additional')}
          />
        </Button>
      </Col>
    </Row>
  );
};

export default memo(Testimonials);
