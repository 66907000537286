/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDto,
  EntryDtoCategoriesEnum,
  EntryDtoEndDevicesEnum,
  EntryDtoStatusEnum,
  EntryDtoTargetGroupsEnum
} from '@SLR/marketplaceService-sdk';
import { isEmptyOrNull } from '@SLR/shared-library';
import {
  ObjectSchema,
  array,
  boolean,
  mixed,
  number,
  object,
  string
} from 'yup';
import { ContactSchema } from './contact';
import { FAQ_MAX_COUNT, FaqSchema } from './faq';
import { TESTIMONIALS_MAX_COUNT, TestimonialSchema } from './testimonial';

export const CATEGORIES_MAX_COUNT = 2;

export const NAME_MAX_CHAR_LENGTH = 50;
export const SUB_HEADLINE_MAX_CHAR_LENGTH = 100;

export const SHORT_DESCRIPTION_MAX_CHAR_LENGTH = 180;
export const DESCRIPTION_MAX_CHAR_LENGTH = 4000;

export const MEDIA_MAX_COUNT = 10;

export const PRICE_INFORMATION_MAX_CHAR_LENGTH = 35;
export const PRICE_LONG_DESCRIPTION_MAX_CHAR_LENGTH = 10000;

export type EntryInput = Nullable<EntryDto>;

// Allow null values
// eslint-disable-next-line
// @ts-ignore
export const EntryInputSchema: ObjectSchema<EntryInput> = object().shape({
  id: string().default(null),
  organizationId: string().default(null),
  created: number().default(0),
  published: boolean().default(false),
  publishedAt: number().default(0),
  updatedAt: number().default(0),
  status: mixed<EntryDtoStatusEnum>().default(EntryDtoStatusEnum.Entwurf),
  logoId: string()
    .required('marketplace.entry.create.formErrors.logoId')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.logoId',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  name: string()
    .required('marketplace.entry.create.formErrors.name')
    .max(NAME_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.name',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  subHeadline: string()
    .required('marketplace.entry.create.formErrors.subHeadline')
    .max(SUB_HEADLINE_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.subHeadline',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  categories: array()
    .of(mixed<EntryDtoCategoriesEnum>().default(null))
    .required('marketplace.entry.create.formErrors.categories')
    .min(1, 'marketplace.entry.create.formErrors.categories')
    .max(CATEGORIES_MAX_COUNT)
    .default(null),
  targetGroups: array()
    .of(mixed<EntryDtoTargetGroupsEnum>())
    .required('marketplace.entry.create.formErrors.targetGroups')
    .min(1, 'marketplace.entry.create.formErrors.targetGroups')
    .default(null),
  endDevices: array()
    .of(mixed<EntryDtoEndDevicesEnum>())
    .nullable()
    .default(null),
  shortDescription: string()
    .required('marketplace.entry.create.formErrors.shortDescription')
    .max(SHORT_DESCRIPTION_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.shortDescription',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  description: string()
    .required('marketplace.entry.create.formErrors.description')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.description',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  declarations: mixed<{ [key: string]: boolean }>().nullable().default(null),
  previewImageId: string()
    .required('marketplace.entry.create.formErrors.previewImageId')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.previewImageId',
      (value) => !isEmptyOrNull(value)
    ),
  media: array().of(string().default(null)).nullable().max(10).default(null),
  testimonials: array()
    .of(TestimonialSchema.defined())
    .nullable()
    .max(TESTIMONIALS_MAX_COUNT)
    .default(null),
  faqs: array()
    .of(FaqSchema.defined())
    .nullable()
    .max(FAQ_MAX_COUNT)
    .default(null),
  priceInformation: string()
    .required('marketplace.entry.create.formErrors.priceInformation')
    .max(PRICE_INFORMATION_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.create.formErrors.priceInformation',
      (value) => !isEmptyOrNull(value)
    )
    .default(null),
  longPriceInformation: string().nullable().default(null),
  contact: ContactSchema.defined().required().default(null)
});
