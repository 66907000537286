/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFormInfo, SLRTextEditor, isEmptyOrNull } from '@SLR/shared-library';
import { FC, memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EntryInput,
  FAQ_ANSWER_MAX_CHAR_LENGTH,
  FAQ_QUESTION_MAX_CHAR_LENGTH
} from '../../..';

type FaqProps = {
  id: string;
  index: number;
  onTitleChange: (title?: string) => void;
};

const FaqForm: FC<FaqProps> = ({ id, index, onTitleChange }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.faqs.form'
  });

  const {
    formState: { errors },
    control,
    watch,
    register
  } = useFormContext<EntryInput>();

  const questionCount = watch(`faqs.${index}.question`)?.length ?? 0;

  return (
    <Row className="flex-column flex-lg-row-reverse faqs-forms-item">
      <Col
        xs="12"
        className={`pb-2 ${errors?.faqs?.[index]?.question ? 'text-danger' : ''}`}
      >
        <Form.Label htmlFor={`faqs.${index}.question`}>
          {t('question')}
        </Form.Label>
        <Form.Control
          id={`faqs.${index}.question`}
          type="text"
          as="textarea"
          rows={2}
          maxLength={FAQ_QUESTION_MAX_CHAR_LENGTH}
          className="form-control-lg"
          isInvalid={!!errors?.faqs?.[index]?.question}
          {...register(`faqs.${index}.question`, {
            onBlur: (e) =>
              onTitleChange(
                isEmptyOrNull(e.target.value) ? undefined : e.target.value
              )
          })}
        />
        <SLRFormInfo
          isInvalid={!!errors?.faqs?.[index]?.question}
          text={errors?.faqs?.[index]?.question?.message}
          counter={{
            count: questionCount,
            maxCount: FAQ_QUESTION_MAX_CHAR_LENGTH
          }}
        />
      </Col>
      <Col
        xs="12"
        className={errors?.faqs?.[index]?.question ? 'text-danger' : ''}
      >
        <Form.Label htmlFor={`faqs.${index}.answer`}>{t('answer')}</Form.Label>
        <SLRTextEditor
          key={id}
          id={`faqs.${index}.answer`}
          control={control}
          name={`faqs.${index}.answer`}
          rows={5}
          plugins={['lists', 'link', 'autolink']}
          maxLength={FAQ_ANSWER_MAX_CHAR_LENGTH}
          className="form-control-lg"
          isInvalid={!!errors?.faqs?.[index]?.answer}
          errorMessage={errors?.faqs?.[index]?.answer?.message}
        />
      </Col>
    </Row>
  );
};

export default memo(FaqForm);
