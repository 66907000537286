/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Declaration, DeclarationIcons, DeclarationReferences } from 'configs';
import { FC, memo } from 'react';
import { Col, Row, RowProps } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './DeclarationItem.scss';

type DeclarationItemProps = {
  declaration: Declaration;
  showCheck?: boolean;
  className?: RowProps['className'];
};

const DeclarationItem: FC<DeclarationItemProps> = ({
  declaration,
  showCheck = false,
  className = 'align-items-start'
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.declarations.options'
  });
  return (
    <Row className={`declaration-item ${className}`}>
      <Col xs="auto" className="rounded-circle icon">
        <FontAwesomeIcon
          icon={getIcon('fal', DeclarationIcons[declaration])}
          color="white"
          size="2x"
        />
      </Col>
      <Col>
        <Row>
          <Col xs="12" className="fw-bold lh-sm d-flex align-items-center">
            <span>{t(`${declaration}.label`)}</span>
            {showCheck && (
              <span className="ms-2 rounded-circle check-icon">
                <FontAwesomeIcon icon={getIcon('fas', 'check')} color="white" />
              </span>
            )}
          </Col>
          <Col xs="12" className="lh-sm">
            <Trans
              t={t}
              i18nKey={`${declaration}.description`}
              components={[
                <Link
                  to={DeclarationReferences[declaration]}
                  target="_blank"
                  key={declaration}
                />
              ]}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(DeclarationItem);
