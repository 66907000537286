/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const toasts = {
  schemaError: {
    title: 'Unvollständige Angaben',
    content:
      'Es gibt noch fehlende oder fehlerhafte Angaben. Bitte prüfe die hervorgehobenen Felder.'
  },
  entryCreate: {
    success: { title: 'Erfolgreich erstellt!' },
    error: {
      title: 'Angebot konnte nicht erstellt werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  entryUpdate: {
    success: { title: 'Erfolgreich übernommen!' },
    error: {
      title: 'Änderungen konnten nicht übernommen werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  entryDelete: {
    success: { title: 'Erfolgreich gelöscht!' },
    error: {
      title: 'Angebot konnte nicht gelöscht werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  sendContactMessage: {
    success: {
      title: 'Nachricht verschickt!',
      content:
        'Deine Nachricht an {{organization}} wurde erfolgreich verschickt!'
    },
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Deine Nachricht konnte nicht verschickt werden. Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  uploadMediaItem: {
    error: {
      title: 'Bild konnte nicht hochgeladen werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  saveMediaItem: {
    error: {
      title: 'Bild konnte nicht gespeichert werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  deleteMediaItem: {
    error: {
      title: 'Bild konnte nicht gelöscht werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  unexpected: {
    error: {
      title: 'Hoppla, etwas lief schief!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  }
};
