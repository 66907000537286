/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FaqDto } from '@SLR/marketplaceService-sdk';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { FC, memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './Faqs.scss';

type FaqsProps = {
  faqs?: FaqDto[];
};

const Faqs: FC<FaqsProps> = ({ faqs }) => (
  <Container className="faqs mt-6">
    <Row className="text-break justify-content-center">
      <Col md="12" lg="8">
        <h2>FAQ</h2>
        {faqs?.map((faq, index) => (
          <Row key={`faq-${faq.id}-${index}`} className="faq-item">
            <Col>
              <h3>{faq.question}</h3>
              <div className="pt-3">
                <MarkdownPreview
                  source={faq.answer}
                  className="table-responsive fs-5 markdown-style"
                  wrapperElement={{ 'data-color-mode': 'light' }}
                />
              </div>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  </Container>
);

export default memo(Faqs);
