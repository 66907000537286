/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRFormInfo,
  SLRLogoUpload,
  SLRMediaSizes,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FC, memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EntryInput, logoEditorIcons } from '../../..';

const ContactForm: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.priceAndContact.contactPerson'
  });

  const {
    formState: { errors },
    getValues,
    control,
    register
  } = useFormContext<EntryInput>();

  return (
    <>
      <SLRFormInfo className="form-label" text={t('title')} />
      <SLRFormInfo text={t('info')} />

      <Row className="flex-column flex-lg-row-reverse mt-3">
        <Col
          xs="auto"
          lg="4"
          xl="3"
          className={`text-center ${errors.contact?.pictureId ? 'text-danger' : ''}`}
        >
          <Form.Label htmlFor="contact.picture">{t('logo')}</Form.Label>
          <SLRLogoUpload
            id="contact.picture"
            name="contact.pictureId"
            control={control}
            mediaSize={SLRMediaSizes.Small}
            mediaOwner={{
              organizationId: getValues('organizationId') ?? undefined,
              owner: Ownership.Organization
            }}
            icons={logoEditorIcons}
          />
          <SLRFormInfo
            isInvalid={!!errors.contact?.pictureId}
            text={errors?.contact?.pictureId?.message}
          />
        </Col>
        <Col xs="12" lg="8" xl="9">
          <Row className="gap-3">
            <Col xs="12" className={errors.contact?.name ? 'text-danger' : ''}>
              <Form.Label htmlFor="contact.name">{t('name')}</Form.Label>

              <Form.Control
                id="contact.name"
                type="text"
                className="form-control-lg"
                isInvalid={!!errors.contact?.name}
                {...register('contact.name')}
              />
              <SLRFormInfo
                isInvalid={!!errors.contact?.name}
                text={errors.contact?.name?.message}
              />
            </Col>
            <Col xs="12" className={errors.contact?.email ? 'text-danger' : ''}>
              <Form.Label htmlFor="contact.email">{t('email')}</Form.Label>
              <Form.Control
                id="contact.email"
                type="text"
                className="form-control-lg"
                isInvalid={!!errors.contact?.email}
                {...register('contact.email')}
              />

              <SLRFormInfo
                isInvalid={!!errors.contact?.email}
                text={errors?.contact?.email?.message}
              />
            </Col>

            <Col xs="12" className={errors.contact?.phone ? 'text-danger' : ''}>
              <Form.Label htmlFor="contact.phone">{t('phone')}</Form.Label>
              <Form.Control
                id="contact.phone"
                type="text"
                className="form-control-lg"
                isInvalid={!!errors.contact?.phone}
                {...register('contact.phone')}
              />
              <SLRFormInfo
                isInvalid={!!errors.contact?.phone}
                text={errors?.contact?.phone?.message}
              />
            </Col>

            <Col
              xs="12"
              className={errors.contact?.website ? 'text-danger' : ''}
            >
              <Form.Label htmlFor="contact.website">{t('website')}</Form.Label>
              <Form.Control
                id="contact.website"
                type="text"
                className="form-control-lg"
                isInvalid={!!errors.contact?.website}
                {...register('contact.website', {
                  setValueAs: (value) =>
                    !isEmptyOrNull(value) && !value.startsWith('http')
                      ? 'https://' + value
                      : value
                })}
              />
              <SLRFormInfo
                isInvalid={!!errors.contact?.website}
                text={errors?.contact?.website?.message}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default memo(ContactForm);
