/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIcon } from '@SLR/shared-library';
import { FC, memo } from 'react';
import { Button } from 'react-bootstrap';
import { CustomArrowProps } from 'react-slick';

export type CarouselArrowProps = {
  direction: 'left' | 'right';
  componentClassName?: string;
  iconTitle: string;
  iconSize?: SizeProp;
};

const CarouselArrow: FC<CustomArrowProps & CarouselArrowProps> = ({
  className,
  style,
  onClick,
  direction,
  componentClassName,
  iconTitle,
  iconSize = '2x'
}) => (
  <Button
    variant="link"
    className={`${className} ${componentClassName}`}
    style={style}
    onClick={onClick}
    title={iconTitle}
  >
    <FontAwesomeIcon
      icon={getIcon(
        'fal',
        direction === 'left' ? 'chevron-left' : 'chevron-right'
      )}
      size={iconSize}
    />
  </Button>
);

export default memo(CarouselArrow);
