/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { isEmptyOrNull } from '@SLR/shared-library';
import { ObjectSchema, object, string } from 'yup';

export type ReviewComment = { comment: string };
export const REVIEW_COMMENT_MAX_CHAR_LENGTH = 5000;

export const ReviewCommentSchema: ObjectSchema<ReviewComment> = object().shape({
  comment: string()
    .required('marketplace.entry.review.input.error')
    .max(REVIEW_COMMENT_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
    .test(
      'isEmpty',
      'marketplace.entry.review.input.error',
      (value) => !isEmptyOrNull(value)
    )
    .default(null)
});
