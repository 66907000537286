/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRSpinner } from '@SLR/shared-library';
import { ErrorView } from 'components';
import { useGetEntry } from 'hooks';
import { FC, memo, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Actions } from '.';
import { EntryDetail, EntryItem } from '../..';

import './EntryReview.scss';

const EntryReview: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.review'
  });

  const { entryId } = useParams();

  const {
    data: entry,
    error,
    isError,
    isPending: isEntryLoading
  } = useGetEntry(entryId);

  const [isPagePreview, setIsPagePreview] = useState<boolean>(true);

  return (
    <Modal show fullscreen className="p-0 entry-review scale">
      <Modal.Header className="p-0">
        <Actions
          id={entryId}
          submittedAt={entry?.updatedAt}
          publishedAt={entry?.publishedAt}
          status={entry?.status}
        />
      </Modal.Header>
      {isEntryLoading ? (
        <SLRSpinner />
      ) : isError ? (
        <ErrorView errorMessage={t('error.notFound')} error={error} />
      ) : (
        <Modal.Body className="p-0">
          <Row className="w-100 m-0 py-3 align-items-center justify-content-between border-bottom">
            <Col>
              <Row className="justify-content-center gap-3">
                <Col xs="auto" className="p-0">
                  <Button
                    size="lg"
                    variant={isPagePreview ? 'primary' : 'outline-primary'}
                    onClick={() => setIsPagePreview(true)}
                  >
                    {t('page')}
                  </Button>
                </Col>
                <Col xs="auto" className="p-0">
                  <Button
                    size="lg"
                    variant={isPagePreview ? 'outline-primary' : 'primary'}
                    onClick={() => setIsPagePreview(false)}
                  >
                    {t('item')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Container fluid className="p-0">
            {isPagePreview ? (
              <EntryDetail isPreview previewEntry={entry} />
            ) : (
              <Container className="px-0">
                <Row
                  xs={1}
                  md={2}
                  xl={3}
                  className="m-0 pt-5 justify-content-center"
                >
                  <EntryItem isPreview entry={entry} />
                </Row>
              </Container>
            )}
          </Container>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default memo(EntryReview);
