/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { useAuth } from '@SLR/shared-library';
import { useMarketplace, useOrganization } from 'providers';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo
} from 'react';

type PublicAccessContextValues = {
  isPublicAccess?: boolean;
};

const defaultPublicAccessValues: PublicAccessContextValues = {
  isPublicAccess: false
};

const PublicAccessContext = createContext<PublicAccessContextValues>(
  defaultPublicAccessValues
);
const usePublicAccess = () => useContext(PublicAccessContext);

const PublicAccessProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { hasAccessByRole } = useMarketplace();
  const { hasAccessByOrganization } = useOrganization();

  const isPublicAccess = useMemo(
    () =>
      !isAuthenticated ||
      !(isAuthenticated && (hasAccessByRole || hasAccessByOrganization)),
    [hasAccessByOrganization, hasAccessByRole, isAuthenticated]
  );

  const publicContextValues: PublicAccessContextValues = useMemo(
    () => ({
      isPublicAccess
    }),
    [isPublicAccess]
  );

  return (
    <PublicAccessContext.Provider value={publicContextValues}>
      {children}
    </PublicAccessContext.Provider>
  );
};

export { PublicAccessProvider, usePublicAccess };
