/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  ApproveEntryRequest,
  GetEntryRequest,
  HTTPRequestInit,
  RejectEntryRequest,
  RemoveEntryRequest,
  RequestOpts,
  ReviewEntriesApi,
  WithdrawEntryRequest
} from '@SLR/marketplaceService-sdk';
import { handleError, isEmptyOrNull } from '@SLR/shared-library';
import { useCallback, useMemo } from 'react';
import { useGetMarketplaceServiceApiConfiguration } from './marketplace.service';

/*
 * Set the body to blob as normal strings are post processed by the SDK using JSON.stringify().
 */

const modifyRequestInit =
  <T extends { body?: string }>(requestParameters: T) =>
  async (requestContext: { init: HTTPRequestInit; context: RequestOpts }) => {
    const { init } = await requestContext;
    if (!isEmptyOrNull(requestParameters.body)) {
      init.body = new Blob([requestParameters.body], {
        type: 'text/plain'
      });
    }
    return init;
  };

const useReviewEntriesApi = () => {
  const configuration = useGetMarketplaceServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new ReviewEntriesApi(configuration),
    [configuration]
  );

  const getEntry = useCallback(
    (requestParameters: GetEntryRequest) =>
      apiInstance.getEntry(requestParameters).catch(handleError),
    [apiInstance]
  );

  const getEntries = useCallback(
    () => apiInstance.getEntries().catch(handleError),
    [apiInstance]
  );

  const approveEntry = useCallback(
    (requestParameters: ApproveEntryRequest) =>
      apiInstance
        .approveEntry(requestParameters, modifyRequestInit(requestParameters))
        .catch(handleError),
    [apiInstance]
  );

  const rejectEntry = useCallback(
    (requestParameters: RejectEntryRequest) =>
      apiInstance
        .rejectEntry(requestParameters, modifyRequestInit(requestParameters))
        .catch(handleError),
    [apiInstance]
  );

  const withdrawEntry = useCallback(
    (requestParameters: WithdrawEntryRequest) =>
      apiInstance
        .withdrawEntry(requestParameters, modifyRequestInit(requestParameters))
        .catch(handleError),
    [apiInstance]
  );

  const removeEntry = useCallback(
    (requestParameters: RemoveEntryRequest) =>
      apiInstance
        .removeEntry(requestParameters, modifyRequestInit(requestParameters))
        .catch(handleError),
    [apiInstance]
  );

  return {
    getEntry,
    getEntries,
    approveEntry,
    rejectEntry,
    withdrawEntry,
    removeEntry
  };
};

export default useReviewEntriesApi;
