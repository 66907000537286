/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto, ResponseError } from '@SLR/marketplaceService-sdk';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'configs';
import { useMarketplace, useOrganization } from 'providers';
import {
  useEntriesApi,
  useManagementEntriesApi,
  useReviewEntriesApi
} from 'services';
import useEntries from './useEntries';

const useGetEntries = () => {
  const { isReviewContext, isManagementContext } = useMarketplace();
  const { selectedOrganizationId: organizationId } = useOrganization();

  const { getEntries } = useReviewEntriesApi();
  const { getPublishedEntries } = useEntriesApi();
  const { getEntriesByOrganization } = useManagementEntriesApi();

  const { onSelect } = useEntries();

  return useQuery<EntryDto[], ResponseError>({
    queryKey: [
      queryKeys.getEntries,
      isReviewContext,
      isManagementContext,
      organizationId
    ],
    queryFn: () =>
      isReviewContext
        ? getEntries()
        : isManagementContext
          ? getEntriesByOrganization({ organizationId })
          : getPublishedEntries(),

    enabled: !(!organizationId && isManagementContext),
    select: (data) => onSelect(data)
  });
};

export default useGetEntries;
