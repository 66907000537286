/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { TestimonialDto } from '@SLR/marketplaceService-sdk';
import { isEmptyOrNull } from '@SLR/shared-library';
import { ObjectSchema, object, string } from 'yup';

export const TESTIMONIALS_MAX_COUNT = 5;
export const TESTIMONIALS_QUOTE_MAX_CHAR_LENGTH = 400;

export const emptyTestimonial: TestimonialDto = {
  id: '',
  authorPictureId: '',
  authorName: '',
  authorRole: '',
  text: ''
};

export const TestimonialSchema: ObjectSchema<Nullable<TestimonialDto>> =
  object().shape({
    id: string().default(null),
    authorPictureId: string()
      .required(
        'marketplace.entry.create.formErrors.testimonials.authorPictureId'
      )
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.testimonials.authorPictureId',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    authorName: string()
      .required('marketplace.entry.create.formErrors.testimonials.authorName')
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.testimonials.authorName',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    authorRole: string()
      .required('marketplace.entry.create.formErrors.testimonials.authorRole')
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.testimonials.authorRole',
        (value) => !isEmptyOrNull(value)
      )
      .default(null),
    text: string()
      .required('marketplace.entry.create.formErrors.testimonials.text')
      .max(TESTIMONIALS_QUOTE_MAX_CHAR_LENGTH, 'form.maxCharLengthError')
      .test(
        'isEmpty',
        'marketplace.entry.create.formErrors.testimonials.text',
        (value) => !isEmptyOrNull(value)
      )
      .default(null)
  });
