/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLibraryConfiguration } from '@SLR/shared-library';
import { KEYCLOAK_CLIENT_ID } from './auth';
import { queryClient } from './query';
import { API_MEDIA_SERVICE_URL, API_USER_SERVICE_URL } from './service';

export const libraryConfiguration: SLRLibraryConfiguration = {
  clientId: KEYCLOAK_CLIENT_ID,
  queryClient: queryClient,
  serviceUrls: {
    user: API_USER_SERVICE_URL,
    media: API_MEDIA_SERVICE_URL
  }
};
